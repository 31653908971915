import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function MainContent() {
    return(
        <div className="main-content">
            <div className="text-content">
                <div className="name">
                    <span>Web Developer</span>
                    <h1>MATTHIEU LE DÉVÉHAT</h1>
                </div>
                <div className="social-media">
                    <a className="btn twitter" href="https://twitter.com/mledev">
                      <FontAwesomeIcon icon={['fab', 'twitter']} size="lg"/>    
                    </a>
                    <a className="btn linkedin" href="https://www.linkedin.com/in/matthieu-le-devehat/">
                      <FontAwesomeIcon icon={['fab', 'linkedin']} size="lg" />    
                    </a>
                </div>
            </div>
        </div>
    );
}

export default MainContent;
