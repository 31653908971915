export default function TopLeftTriangle(p){
    let canvas;

    let resX = 5.0;
    let resY = 5.0;
    let cols, rows;
    let rainLength = 20;
    let currentRows = 0;

    p.setup = () => {
      canvas = p.createCanvas(p.windowWidth, p.windowHeight);
      
      p.colorMode(p.HSB);
      p.frameRate(20);
      
      let spaceX = p.width;
      let spaceY = p.height;
      
      cols = p.floor(spaceX / resX);
      rows = p.floor(spaceY / resY);
    }

    p.draw = () => {
      p.background(0,0,0);
      
      p.rectMode(p.CENTER);
        
      for(let c = 0; c<cols; c++){
        let noising = p.noise(c * 40) * cols;
        let temp = (currentRows + noising) / (noising * 0.005);
        
        temp = temp % (cols + rainLength);
          for(let r = 0; r<rows; r++){
        
          let x = c * resX + resX / 2.0;
          let y = r * resY + resY / 2.0;
          
                
          if(r <= temp && r > temp - rainLength){
            let color = 100/(temp+1-r);
            
            p.noStroke();
            p.fill(203, 100, color);
            p.rect(x, y, resX, resY);
          }
          
        }
      }
      
      currentRows = currentRows+1;
    }

    p.windowResized = () => {
      p.resizeCanvas(p.windowWidth, p.windowHeight);
      let spaceX = p.width;
      let spaceY = p.height;
      
      cols = p.floor(spaceX / resX);
      rows = p.floor(spaceY / resY);

      p.frameRate(30);
    }
}