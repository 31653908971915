import React from 'react';
import P5Wrapper from 'react-p5-wrapper';
import './App.scss';
import MainContent from './component/MainContent';
import TopLeftTriangle from './component/triangle/TopLeftTriangle';
import BottomRightTriangle from './component/triangle/BottomRightTriangle';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fab)


function App() {
  return (
    <div className="App">
      {/*<P5Wrapper sketch={TopLeftTriangle} color='red'></P5Wrapper>*/}
      <P5Wrapper sketch={BottomRightTriangle}></P5Wrapper>
      <div className="gradient-background"></div>
      <MainContent/>
    </div>
  );
}

export default App;
